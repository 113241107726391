import * as React from "react";
import * as ReactDOM from "react-dom";
import "./index.css";
// Candidates: 03, 08, 10, 11, 15, 16, 22
import "./_palettes/palette-08.css";
import "./_palettes/palette-action-02.css";
import "./App.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
