/* eslint-disable */

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const qs = require("qs");
const NodeCache = require("node-cache");
export class SDK {
    constructor(opts) {
        var _a;
        this.removeIdentityApiKey = function (params, query, opts) {
            let path = "/v1/identities/:identity_uuid/api-keys/:api_key_uuid";
            return this._delete(path, params, query, opts);
        };
        this.removeOrganizationApiKey = function (params, query, opts) {
            let path = "/v1/organizations/:uuid/api-keys/:api_key_uuid";
            return this._delete(path, params, query, opts);
        };
        this.removeIdentityFromOrganization = function (params, query, opts) {
            let path = "/v1/organizations/:uuid/identities/:identity_username";
            return this._delete(path, params, query, opts);
        };
        this.removeIpGroup = function (params, query, opts) {
            let path = "/v1/organizations/:uuid/ip-groups/:ip_group_uuid";
            return this._delete(path, params, query, opts);
        };
        this.__setBaseUrl(opts.baseUrl);
        if (opts === null || opts === void 0 ? void 0 : opts.headers) {
            this._headers = (_a = opts.headers) !== null && _a !== void 0 ? _a : {};
        }
        this._cache = new NodeCache({
            // 10 min
            stdTTL: 600,
            // 5 min
            checkperiod: 300
        });
    }
    __setBaseUrl(baseUrl) {
        this._baseUrl = baseUrl;
    }
    __setHeaders(fn) {
        this._headers = fn(this._headers);
    }
    __clearCache() {
        this._cache.flushAll();
    }
    getAttackSource(params, query, opts) {
        let path = "/v1/attack-sources/:ip";
        return this._get(path, params, query, opts);
    }
    getAutonomousSystemList(params, query, opts) {
        let path = "/v1/autonomous-systems/";
        return this._get(path, params, query, opts);
    }
    getClientTypeList(params, query, opts) {
        let path = "/v1/client-types/";
        return this._get(path, params, query, opts);
    }
    getClientType(params, query, opts) {
        let path = "/v1/client-types/:uuid";
        return this._get(path, params, query, opts);
    }
    getClientList(params, query, opts) {
        let path = "/v1/clients/";
        return this._get(path, params, query, opts);
    }
    createClient(params, data, query, opts) {
        let path = "/v1/clients/";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getClient(params, query, opts) {
        let path = "/v1/clients/:uuid";
        return this._get(path, params, query, opts);
    }
    updateClient(params, data, query, opts) {
        let path = "/v1/clients/:uuid";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    getClientMetrics(params, query, opts) {
        let path = "/v1/clients/:uuid/metrics";
        return this._get(path, params, query, opts);
    }
    completePasswordReset(params, data, query, opts) {
        let path = "/v1/complete-reset-password";
        return this._post(path, params, data, query, opts, "application/json");
    }
    runHealthCheck(params, query, opts) {
        let path = "/v1/health-check";
        return this._get(path, params, query, opts);
    }
    createIdentity(params, data, query, opts) {
        let path = "/v1/identities/";
        return this._post(path, params, data, query, opts, "application/json");
    }
    addIdentityApiKey(params, data, query, opts) {
        let path = "/v1/identities/:identity_uuid/api-keys";
        return this._post(path, params, data, query, opts, undefined);
    }
    updateIdentity(params, data, query, opts) {
        let path = "/v1/identities/:user_uuid";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    getIdentityToken(params, data, query, opts) {
        let path = "/v1/login";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getMe(params, query, opts) {
        let path = "/v1/me";
        return this._get(path, params, query, opts);
    }
    getOrganizationList(params, query, opts) {
        let path = "/v1/organizations/";
        return this._get(path, params, query, opts);
    }
    createOrganization(params, data, query, opts) {
        let path = "/v1/organizations/";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getOrganization(params, query, opts) {
        let path = "/v1/organizations/:uuid";
        return this._get(path, params, query, opts);
    }
    updateOrganization(params, data, query, opts) {
        let path = "/v1/organizations/:uuid";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    claimAutonomousSystem(params, data, query, opts) {
        let path = "/v1/organizations/:uuid/claim-autonomous-system";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    disownAutonomousSystem(params, data, query, opts) {
        let path = "/v1/organizations/:uuid/disown-autonomous-system";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    getIdentitiesForOrganization(params, query, opts) {
        let path = "/v1/organizations/:uuid/identities";
        return this._get(path, params, query, opts);
    }
    addIdentityToOrganization(params, data, query, opts) {
        let path = "/v1/organizations/:uuid/identities/:identity_username";
        return this._patch(path, params, data, query, opts, undefined);
    }
    getIpGroupList(params, query, opts) {
        let path = "/v1/organizations/:uuid/ip-groups";
        return this._get(path, params, query, opts);
    }
    addIpGroupToOrganization(params, data, query, opts) {
        let path = "/v1/organizations/:uuid/ip-groups";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getIpGroup(params, query, opts) {
        let path = "/v1/organizations/:uuid/ip-groups/:ip_group_uuid";
        return this._get(path, params, query, opts);
    }
    getOrganizationReport(params, query, opts) {
        let path = "/v1/organizations/:uuid/report";
        return this._get(path, params, query, opts, "application/json");
    }
    getAttackTypeMetrics(params, query, opts) {
        let path = "/v1/dis-metrics/attack-type-metrics";
        return this._get(path, params, query, opts, "application/json");
    }
    getSourceCountMetrics(params, query, opts) {
        let path = "/v1/dis-metrics/source-count-metrics";
        return this._get(path, params, query, opts, "application/json");
    }
    getEventCountMetrics(params, query, opts) {
        let path = "/v1/dis-metrics/event-count-metrics";
        return this._get(path, params, query, opts, "application/json");
    }
    getSourceBPSAverageMetrics(params, query, opts) {
        let path = "/v1/dis-metrics/source-bps-avg-metrics";
        return this._get(path, params, query, opts, "application/json");
    }
    getSourcePPSAverageMetrics(params, query, opts) {
        let path = "/v1/dis-metrics/source-pps-avg-metrics";
        return this._get(path, params, query, opts, "application/json");
    }
    getEventBPSAverageMetrics(params, query, opts) {
        let path = "/v1/dis-metrics/event-bps-avg-metrics";
        return this._get(path, params, query, opts, "application/json");
    }
    getEventPPSAverageMetrics(params, query, opts) {
        let path = "/v1/dis-metrics/event-pps-avg-metrics";
        return this._get(path, params, query, opts, "application/json");
    }
    getSourceCountOriginMetrics(params, query, opts) {
        let path = "/v1/dis-metrics/source-count-origin-metrics";
        return this._get(path, params, query, opts, "application/json");
    }
    getSourceBPSAverageOriginMetrics(params, query, opts) {
        let path = "/v1/dis-metrics/source-bps-avg-origin-metrics";
        return this._get(path, params, query, opts, "application/json");
    }
    getSourcePPSAverageOriginMetrics(params, query, opts) {
        let path = "/v1/dis-metrics/source-pps-avg-origin-metrics";
        return this._get(path, params, query, opts, "application/json");
    }
    getAllEmailTypes(params, query, opts) {
        let path = "/v1/email-interface/email-types";
        return this._get(path, params, query, opts, "application/json");
    }
    optIntoEmailType(params, data, query, opts) {
        let path = "/v1/email-interface/:identity_uuid/user-email-types";
        return this._post(path, params, data, query, opts, "application/json");
    }
    modifyOptIntoEmailType(params, data, query, opts) {
        let path = "/v1/email-interface/:identity_uuid/user-email-types";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    optOutEmailType(params, query, opts) {
        let path = "/v1/email-interface/:identity_uuid/user-email-types";
        return this._delete(path, params, query, opts, "application/json");
    }
    getUserEmailTypes(params, query, opts) {
        let path = "/v1/email-interface/:identity_uuid/user-email-types";
        return this._get(path, params, query, opts, "application/json");
    }
    createPermission(params, data, query, opts) {
        let path = "/v1/permissions/";
        return this._post(path, params, data, query, opts, "application/json");
    }
    updatePermission(params, data, query, opts) {
        let path = "/v1/permissions/:permission_uuid";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    requestPasswordReset(params, data, query, opts) {
        let path = "/v1/reset-password";
        return this._post(path, params, data, query, opts, undefined);
    }
    getRoleList(params, query, opts) {
        let path = "/v1/roles/";
        return this._get(path, params, query, opts);
    }
    createRole(params, data, query, opts) {
        let path = "/v1/roles/";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getRole(params, query, opts) {
        let path = "/v1/roles/:uuid";
        return this._get(path, params, query, opts);
    }
    updateRole(params, data, query, opts) {
        let path = "/v1/roles/:uuid";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    addIdentityToRole(params, data, query, opts) {
        let path = "/v1/roles/:uuid/identities/:identity_uuid";
        return this._patch(path, params, data, query, opts, undefined);
    }
    removeIdentityFromRole(params, data, query, opts) {
        let path = "/v1/roles/:uuid/identities/:identity_uuid";
        return this._delete(path, params, data, query, opts, undefined);
    }
    getRolesForIdentity(params, query, opts) {
        let path = "/v1/roles/identities/:identity_uuid";
        return this._get(path, params, query, opts);
    }
    addOrganizationApiKey(params, data, query, opts) {
        let path = "/v1/organizations/:uuid/api-keys";
        return this._post(path, params, data, query, opts, undefined);
    }
    addApiKeyToRole(params, data, query, opts) {
        let path = "/v1/roles/:uuid/api-keys/:api_key_uuid";
        return this._patch(path, params, data, query, opts, undefined);
    }
    removeApiKeyFromRole(params, data, query, opts) {
        let path = "/v1/roles/:uuid/api-keys/:api_key_uuid";
        return this._delete(path, params, data, query, opts, undefined);
    }
    getRolesForApiKey(params, query, opts) {
        let path = "/v1/roles/api-keys/:api_key_uuid";
        return this._get(path, params, query, opts);
    }
    superUpsertAutonomousSystem(params, data, query, opts) {
        let path = "/v1/super/autonomous-systems/";
        return this._post(path, params, data, query, opts, "application/json");
    }
    superUpdateAutonomousSystem(params, data, query, opts) {
        let path = "/v1/super/autonomous-systems/:autonomous_system_uuid";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    getSystemResourceList(params, query, opts) {
        let path = "/v1/system-resources/";
        return this._get(path, params, query, opts);
    }
    getIpRangeFromCidr(params, query, opts) {
        let path = "/v1/system/ip-range-from-cidr/:ip/:cidr";
        return this._get(path, params, query, opts);
    }
    startWorker(params, query, opts) {
        let path = "/v1/system/start-worker/:queue_name";
        return this._get(path, params, query, opts);
    }
    getAllWorkers(params, query, opts) {
        let path = "/v1/system/workers";
        return this._get(path, params, query, opts);
    }
    getAbuseContactList(params, query, opts) {
        let path = "/v1/system/abuse-contact-list";
        return this._get(path, params, query, opts);
    }
    superGetEventLog(params, query, opts) {
        let path = "/v1/super/event-logger/";
        return this._get(path, params, query, opts);
    }
    superGetEventLogTypeList(params, query, opts) {
        let path = "/v1/super/event-logger/event-log-type-list";
        return this._get(path, params, query, opts);
    }
    _get(path, params, query, opts) {
        return __awaiter(this, void 0, void 0, function* () {
            const { hydratedPath } = findParamArguments(path, params);
            const url = `${this._baseUrl}${hydratedPath}${generateQueryString(query)}`;
            const cacheKey = `GET${url}`;
            if ((opts === null || opts === void 0 ? void 0 : opts.strategy) !== "network") {
                const cached = this._cache.get(cacheKey);
                if (cached !== undefined) {
                    return cached;
                }
            }
            let headers = { "Content-Type": "application/json" };
            if (this._headers) {
                headers = Object.assign(Object.assign({}, headers), this._headers);
            }
            if (opts === null || opts === void 0 ? void 0 : opts.headers) {
                headers = Object.assign(Object.assign({}, headers), opts.headers);
            }
            let res = yield fetch(url, { headers });
            const json = yield res.json();
            if (!res.ok) {
                return { error: json, status: res.status };
            }
            const out = { data: json, status: res.status };
            this._cache.set(cacheKey, out);
            return out;
        });
    }
    _post(path, params, data, query, opts, requestBodyContentType) {
        return __awaiter(this, void 0, void 0, function* () {
            const { hydratedPath } = findParamArguments(path, params);
            let headers = !requestBodyContentType
                ? {}
                : { "Content-Type": requestBodyContentType };
            if (this._headers) {
                headers = Object.assign(Object.assign({}, headers), this._headers);
            }
            if (opts === null || opts === void 0 ? void 0 : opts.headers) {
                headers = Object.assign(Object.assign({}, headers), opts.headers);
            }
            let res = yield fetch(`${this._baseUrl}${hydratedPath}${generateQueryString(query)}`, {
                method: "POST",
                headers,
                body: requestBodyContentType === "application/json"
                    ? JSON.stringify(data)
                    : data
            });
            if (res.status === 204) {
                if ((opts === null || opts === void 0 ? void 0 : opts.clearCache) !== false) {
                    this._cache.flushAll();
                }
                return { data: undefined, status: res.status };
            }
            const json = yield res.json();
            if (!res.ok) {
                return { error: json, status: res.status };
            }
            if ((opts === null || opts === void 0 ? void 0 : opts.clearCache) !== false) {
                this._cache.flushAll();
            }
            return { data: json, status: res.status };
        });
    }
    _patch(path, params, data, query, opts, requestBodyContentType) {
        return __awaiter(this, void 0, void 0, function* () {
            const { hydratedPath } = findParamArguments(path, params);
            let headers = !requestBodyContentType
                ? {}
                : { "Content-Type": requestBodyContentType };
            if (this._headers) {
                headers = Object.assign(Object.assign({}, headers), this._headers);
            }
            if (opts === null || opts === void 0 ? void 0 : opts.headers) {
                headers = Object.assign(Object.assign({}, headers), opts.headers);
            }
            let res = yield fetch(`${this._baseUrl}${hydratedPath}${generateQueryString(query)}`, {
                method: "PATCH",
                headers,
                body: requestBodyContentType === "application/json"
                    ? JSON.stringify(data)
                    : data
            });
            if (res.status === 204) {
                if ((opts === null || opts === void 0 ? void 0 : opts.clearCache) !== false) {
                    this._cache.flushAll();
                }
                return { data: undefined, status: res.status };
            }
            const json = yield res.json();
            if (!res.ok) {
                return { error: json, status: res.status };
            }
            if ((opts === null || opts === void 0 ? void 0 : opts.clearCache) !== false) {
                this._cache.flushAll();
            }
            return { data: json, status: res.status };
        });
    }
    _put(path, params, data, query, opts, requestBodyContentType) {
        return __awaiter(this, void 0, void 0, function* () {
            const { hydratedPath } = findParamArguments(path, params);
            let headers = !requestBodyContentType
                ? {}
                : { "Content-Type": requestBodyContentType };
            if (this._headers) {
                headers = Object.assign(Object.assign({}, headers), this._headers);
            }
            if (opts === null || opts === void 0 ? void 0 : opts.headers) {
                headers = Object.assign(Object.assign({}, headers), opts.headers);
            }
            let res = yield fetch(`${this._baseUrl}${hydratedPath}${generateQueryString(query)}`, {
                method: "PUT",
                headers,
                body: requestBodyContentType === "application/json"
                    ? JSON.stringify(data)
                    : data
            });
            if (res.status === 204) {
                if ((opts === null || opts === void 0 ? void 0 : opts.clearCache) !== false) {
                    this._cache.flushAll();
                }
                return { data: undefined, status: res.status };
            }
            const json = yield res.json();
            if (!res.ok) {
                return { error: json, status: res.status };
            }
            if ((opts === null || opts === void 0 ? void 0 : opts.clearCache) !== false) {
                this._cache.flushAll();
            }
            return { data: json, status: res.status };
        });
    }
    _delete(path, params, query, opts) {
        return __awaiter(this, void 0, void 0, function* () {
            const { hydratedPath } = findParamArguments(path, params);
            let headers = { "Content-Type": "application/json" };
            if (this._headers) {
                headers = Object.assign(Object.assign({}, headers), this._headers);
            }
            if (opts === null || opts === void 0 ? void 0 : opts.headers) {
                headers = Object.assign(Object.assign({}, headers), opts.headers);
            }
            let res = yield fetch(`${this._baseUrl}${hydratedPath}${generateQueryString(query)}`, { method: "DELETE", headers });
            if (res.status === 204) {
                if ((opts === null || opts === void 0 ? void 0 : opts.clearCache) !== false) {
                    this._cache.flushAll();
                }
                return { data: undefined, status: res.status };
            }
            const json = yield res.json();
            if (!res.ok) {
                return { error: json, status: res.status };
            }
            if ((opts === null || opts === void 0 ? void 0 : opts.clearCache) !== false) {
                this._cache.flushAll();
            }
            return { data: json, status: res.status };
        });
    }
}
function findParamArguments(path, obj) {
    let parts = path.split(/\//);
    let params = parts.filter(p => p.length > 0 && p[0] === ":");
    let args = params.map(p => p.substring(1, p.length));
    let out = {};
    let hydratedPath = path;
    if (obj) {
        args.forEach(e => {
            out[e] = obj[e];
            hydratedPath = hydratedPath.replace(`:${e}`, obj[e]);
        });
    }
    return { args, out, hydratedPath };
}
function generateQueryString(opts) {
    return qs.stringify(opts, { addQueryPrefix: true, encode: true });
}
