import * as React from "react";
import styles from "./DefaultErrorBoundary.module.css";

export default class DefaultErrorBoundary extends React.Component<
  { children: any },
  { hasError: boolean }
> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.root}>
          <h1 className={styles.heading}>Something went wrong :(</h1>
          <div className={styles.sub_heading}>Please reload the page.</div>
        </div>
      );
    }
    return this.props.children;
  }
}
