import * as React from "react";
import styles from "./GenericErrorMessage.module.css";

export default function GenericErrorMessage(props: { children?: any }) {
  return (
    <div className={styles.error_root}>
      <div className={styles.error_container}>
        <div className={styles.error_container_text}>
          {props.children ?? "Something went wrong. Please reload the page."}
        </div>
      </div>
    </div>
  );
}
