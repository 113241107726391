import * as React from "react";
import { UserContext } from "./UserContext";

export function useSelectedOrganizationUuid() {
  const { user } = React.useContext(UserContext);
  return {
    selectedOrganizationUuid: user.organizationList.find(
      (x: any) => x.isSelected
    )?.uuid,
  };
}
