import * as React from "react";
import { render } from "react-dom";
import styles from "./Toast.module.css";

export default function Toast(props: {
  children: string;
  type?: "success" | "error";
}) {
  return (
    <div
      className={
        props.type
          ? styles.toast + " " + styles["toast_" + props.type]
          : styles.toast
      }
    >
      {props.children}
    </div>
  );
}

let timer_id: ReturnType<typeof setTimeout>;

export function showToast(toast: any, opts?: { duration?: number }) {
  opts = opts || {};
  const ms = opts.duration || 4000;

  const id = "toast_container";
  if (!document.getElementById(id)) {
    const toast_container_el = document.createElement("div");
    toast_container_el.id = id;
    document.body.appendChild(toast_container_el);
  }
  render(toast, document.getElementById(id));
  clearTimeout(timer_id);
  timer_id = setTimeout(() => {
    const toast_container_el = document.getElementById(id);
    if (toast_container_el) {
      toast_container_el.remove();
    }
  }, ms);
}
