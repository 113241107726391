import * as React from "react";
import cx from "classcat";
import { Link } from "react-router-dom";
import { MdArrowDropDown } from "react-icons/md";
import { BsThreeDots } from "react-icons/bs";
import { useAsyncCallback } from "react-async-hook";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuLink,
} from "@reach/menu-button";
import Media from "react-media";
import { ReactComponent as Logo } from "../assets/Logo.svg";
import { useSDK } from "../useSDK";
import { useUser } from "../useUser";
import { useSelectedOrganizationUuid } from "../useSelectedOrganizationUuid";
import Toast, { showToast } from "./lib/Toast";
import { getLinks } from "./Sidebar";
import styles from "./Header.module.css";

export default function Header(props: { heading?: string }) {
  const { sdk } = useSDK();
  const { user } = useUser();
  const { selectedOrganizationUuid } = useSelectedOrganizationUuid();

  function changeSelectedOrganization(uuid: string) {
    sdk.updateOrganization({ uuid }, { isSelected: true }).then((result) => {
      if (result.error) {
        showToast(
          <Toast type="error">
            {result.error.detail ?? "Something went wrong. Please try again."}
          </Toast>
        );
      } else {
        window.location.href = "/";
      }
    });
  }
  const handleSelect = useAsyncCallback(changeSelectedOrganization);

  const selectedOrganization = user.organizationList.find(
    (x: any) => x.uuid === selectedOrganizationUuid
  );

  return (
    <header className={styles.header}>
      <div className={styles.start_layout}>
        <div className={styles.logo_container}>
          <Link to="/home" className={styles.link}>
            <Logo className={styles.logo} />
          </Link>
        </div>
        {props.heading == null ? null : (
          <div className={styles.heading}>{props.heading}</div>
        )}
      </div>
      <div className={styles.actions}>
        {user.organizationList.length > 0 ? (
          <Menu>
            <MenuButton
              className={styles.menu_button}
              aria-label="Organizations"
            >
              {selectedOrganization.name ?? "--"}
              <MdArrowDropDown />
            </MenuButton>
            <MenuList className={styles.menu_list}>
              <header className={styles.roles_header}>
                <div className={styles.roles_heading}>Organizations</div>
              </header>
              {user.organizationList.map((o: any) => (
                <MenuItem
                  key={o.uuid}
                  onSelect={() => {
                    handleSelect.execute(o.uuid);
                  }}
                  className={cx([
                    styles.menu_item,
                    styles.menu_item_dense,
                    o.isSelected && styles.menu_item_current,
                  ])}
                >
                  <span className={styles.capitalize}>
                    {o.name.toLowerCase()}
                  </span>
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        ) : null}
        <Menu>
          <MenuButton className={styles.menu_button} aria-label="Menu">
            <BsThreeDots />
          </MenuButton>
          <MenuList className={styles.menu_list}>
            <Media queries={{ large: "(min-width: 768px)" }}>
              {(matches) =>
                matches.large ? null : (
                  <>
                    {getLinks(user.selectedRole, selectedOrganizationUuid).map(
                      (x, i) => (
                        <MenuLink
                          key={i}
                          as={Link}
                          to={x.to}
                          className={styles.menu_item}
                        >
                          {x.text}
                        </MenuLink>
                      )
                    )}
                  </>
                )
              }
            </Media>
            <MenuItem
              onSelect={() => {
                window.location.href = "/profile";
              }}
              className={styles.menu_item}
            >
              Profile
            </MenuItem>
            <MenuItem
              onSelect={() => {
                window.localStorage.clear();
                window.location.href = "/login";
              }}
              className={styles.menu_item}
            >
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
      </div>
    </header>
  );
}
