import * as React from "react";
import { NavLink } from "react-router-dom";
import cx from "classcat";
import {
  MdDashboard,
  MdEqualizer,
  MdWarning,
  MdRouter,
  MdBusiness,
  MdGroup,
  MdGroupWork,
  MdInsertChart,
  MdEmail,
  MdEventNote,
} from "react-icons/md";
import { useUser } from "../useUser";
import { useSelectedOrganizationUuid } from "../useSelectedOrganizationUuid";
import styles from "./Sidebar.module.css";

// const width = "60px";
// const widthCollapsed = "37px";

export default function Sidebar() {
  const { user } = useUser();
  const { selectedOrganizationUuid } = useSelectedOrganizationUuid();
  // const [isSidebarCollapsed, setIsSidebarCollapsed] = React.useState(false);
  // React.useEffect(() => {
  //   const value = isSidebarCollapsed ? widthCollapsed : width;
  //   document.documentElement.style.setProperty("--sidebar-width", value);
  // });

  return (
    <div className={styles.sidebar}>
      <nav className={styles.nav}>
        <ul className={styles.nav_list}>
          {getLinks(user.selectedRole, selectedOrganizationUuid).map((x, i) => {
            const Icon = x.icon;
            return (
              <li key={i}>
                <NavLink
                  exact
                  to={x.to}
                  className={cx([
                    styles.nav_link,
                    // isSidebarCollapsed && styles.nav_link_collapsed,
                  ])}
                  activeClassName={styles.nav_link_active}
                >
                  <Icon aria-hidden="true" className={styles.nav_link_icon} />
                  {/* {isSidebarCollapsed ? null : (
                    <span className={styles.nav_link_text}></span>
                  )} */}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </nav>
      {/* <div
        className={cx([
          styles.collapse_button_container,
          isSidebarCollapsed && styles.collapse_button_container_collapsed,
        ])}
      >
        <button
          type="button"
          onClick={() => {
            setIsSidebarCollapsed(!isSidebarCollapsed);
            const nextValue = isSidebarCollapsed ? width : widthCollapsed;
            document.documentElement.style.setProperty(
              "--sidebar-width",
              nextValue
            );
          }}
          className={buttonStyles.button_transparent_neutral}
        >
          {isSidebarCollapsed ? <MdChevronRight /> : <MdChevronLeft />}
        </button>
      </div> */}
    </div>
  );
}

export function getLinks(role: string, selectedOrganizationUuid: string) {
  return [
    {
      text: "Dashboard",
      to: "/home",
      icon: MdDashboard,
    },
    {
      text: "Threats",
      to: "/threats",
      icon: MdWarning,
    },
    {
      text: "Clients",
      to: "/clients",
      icon: MdRouter,
    },
    {
      text: "Client Metrics",
      to: "/client-metrics",
      icon: MdEqualizer,
    },
    {
      text: "Organization",
      to: `/organizations/${selectedOrganizationUuid}`,
      icon: MdBusiness,
    },
    {
      text: "Users",
      to: `/organizations/${selectedOrganizationUuid}/users`,
      icon: MdGroup,
    },
    {
      text: "ASNs",
      to: `/asns`,
      icon: MdGroupWork,
    },
    {
      text: "DIS Metrics",
      to: "/dis-metrics",
      icon: MdInsertChart,
    },
    {
      text: "Email Interface",
      to: "/email-interface",
      icon: MdEmail,
    },
    {
      text: "Event Logger",
      to: "/select-event-logger",
      icon: MdEventNote,
    },
  ];
}
