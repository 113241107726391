import * as React from "react";
import { Delay } from "./lib/Delay";
import { CircularProgress } from "./lib/CircularProgress";
import styles from "./FullPageLoading.module.css";

export default function FullPageLoading() {
  return (
    <div className={styles.loading_container}>
      <Delay>
        <CircularProgress />
      </Delay>
    </div>
  );
}
