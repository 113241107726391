import * as React from "react";
// import SDK from 'ocat-client-sdk'

// type ThenArg<T> = T extends PromiseLike<infer U> ? U : T
// type User = ThenArg<ReturnType<SDK['getMe']>>

export const UserContext = React.createContext<{
  user: any;
}>({
  user: null
});
